
<template>
	<v-app>
		<div v-if="$store.getters.auth.isAuth && $store.state.init_state">
			<APPNavbar class="no-print"> </APPNavbar>
			<v-main>
				<router-view class="mx-2" />

			</v-main>
              <v-col class="text-center my-0 py-2" style="background-color: #043066;">
                    <span style="color: white;">Top IT © 2024</span>
                </v-col>
            <!-- footer in the end of the page then show -->
             <!-- <v-footer  app color="primary">
                <v-col class="text-center my-0 py-0">
                    <span >Top IT © 2024</span>
                </v-col>
            </v-footer> -->
		</div>
		<!-- <div v-else-if="$store.state.init_state"> -->
		<div v-else>
			<v-main>
				<router-view />

			</v-main>
            <!-- fotter all pages -->
            <v-footer app>
                <v-col class="text-center">
                    <span class="caption">
                        Top IT © 2024</span>
                </v-col>
            </v-footer>

		</div>
	</v-app>
</template>
<script setup>
	import { ref, onMounted } from 'vue';
import APPNavbar from './components/Navbar';
import { auth } from './firebase';
import { useStore } from 'vuex';

// Call useStore() outside of the onAuthStateChanged callback
const store = useStore();
onMounted(() => {
  auth.onAuthStateChanged(user => {
    if (user) {
      console.log(user.uid)
      store.dispatch('init', user.uid);
    } else {
        store.dispatch('logout');
    }
  })
});

</script>
      
        