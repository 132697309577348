import { initializeApp } from "firebase/app";

import "firebase/auth";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDiir5Pn4wutYBbSAV92Wbb1piwYmnnGr0",
    authDomain: "nawandi-nwe.firebaseapp.com",
    projectId: "nawandi-nwe",
    storageBucket: "nawandi-nwe.appspot.com",
    messagingSenderId: "934667049956",
    appId: "1:934667049956:web:2549525016fd2504f3c5f1",
    measurementId: "G-N2GK28D0Z9"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth, app, signInWithEmailAndPassword, sendPasswordResetEmail }
