
<template>
	<div>
		<v-container fluid class="login-container">
			<v-row class="login-container d-flex align-center justify-center">

				<v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" order-sm="1" order-md="2">
					<div class="login-container align-center justify-center " style="height:100%">
						<v-img :src="logbw" alt="Logo" class="login-image" />
					</div>
				</v-col>

				<v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="bg-main-1 d-flex align-center" order-sm="2" order-md="1">
					<v-card width="100%" color="transparent" class="elevation-0 px-2">

						<v-card-subtitle>
							Welcome back.
						</v-card-subtitle>
						<v-card-text class="mt-3">
							<form @submit.prevent="login">
								<v-text-field autocomplete="false" density="compact" class="mx-1" variant="outlined" required label="Email" v-model="user.email">
								</v-text-field>
								<v-text-field v-model="user.password" autocomplete="false" density="compact" class="mx-1" variant="outlined" required type="password" label="Password">
								</v-text-field>
								<v-btn type="submit" block color="primary" :loading="btn_loading" style="color:white">
									Login</v-btn>
							</form>
						</v-card-text>
						<v-card-text class="text-center">
							<h3>
								<v-btn to="/reset-password" text>forgot password?</v-btn>
							</h3>
							<br>
						</v-card-text>

					</v-card>
				</v-col>

			</v-row>
			<v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12" order-sm="3" order-md="3">
				<div class=" align-center justify-center " style="text-align: -webkit-center;">
					<a href="https://www.topitcompany.io/" target="_blank">
						<v-img :src="topit" alt="Logo" class="login-image" width="20vh" />
					</a>
				</div>

			</v-col>
		</v-container>

	</div>
</template>
<script setup>
	import {
		auth,
		signInWithEmailAndPassword
	} from '../firebase'
	import {
		onMounted,
		ref
	} from 'vue'
import { toast } from "vue3-toastify";
    
	const logbw = require('@/assets/nawandi_nwe_logo.png')
	const topit = require('@/assets/login.png');

	const login = () => {
		signInWithEmailAndPassword(auth, user.value.email, user.value.password)
			.then((r) => {
				localStorage.setItem('auth', 'auth')
				window.location.href = '/'
			})
			.catch(err => {
				  toast('Incorrect Password or Email' , {
                type: 'error',
                
            })
				console.log(err)
			})
	}

	onMounted(() => {
		// if (localStorage.getItem('auth')) {
		//     window.location.href = '/'            
		// }

		// if (process.env.NODE_ENV === 'development') {
		//     user.value.email = 'admin@void.krd'
		//     user.value.password = '12345678'
		// }
	})


	let user = ref({
		email: '',
		password: ''
	})
</script>
<style lang="scss" scoped>
	.login-container {
		height: 80vh;
	}
</style>
        