
import { createRouter, createWebHistory } from 'vue-router'
import {
    auth
} from '../firebase'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('./../views/Home.vue'),
        meta: {
            auth: false,
            title: 'Home'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./../views/Login.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('./../views/ResetPassword.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },
    {
        path: '/options',
        name: 'Options',
        component: () => import('./../views/Options.vue'),
        meta: {
            auth: true,
            title: 'Options'
        }
    },

    {
        path: '/antibiotic_and_medications-list',
        name: 'ListAntibiotic_and_medications',
        component: () => import('./../views/Antibiotic_and_medications/List.vue'),
        meta: {
            auth: true,
            title: 'Antibiotic_and_medications List'
        }
    },
    {
        path: '/antibiotic_and_medications-list/:id',
        name: 'ViewAntibiotic_and_medications',
        component: () => import('./../views/Antibiotic_and_medications/Edit.vue'),
        meta: {
            auth: true,
            title: 'Antibiotic_and_medications Edit'
        }
    },
    {
        path: '/case_doctor_plans-list',
        name: 'ListCase_doctor_plans',
        component: () => import('./../views/Case_doctor_plans/List.vue'),
        meta: {
            auth: true,
            title: 'Case_doctor_plans List'
        }
    },
    {
        path: '/case_doctor_plans-list/:id',
        name: 'ViewCase_doctor_plans',
        component: () => import('./../views/Case_doctor_plans/Edit.vue'),
        meta: {
            auth: true,
            title: 'Case_doctor_plans Edit'
        }
    },
    {
        path: '/case_dressings-list',
        name: 'ListCase_dressings',
        component: () => import('./../views/Case_dressings/List.vue'),
        meta: {
            auth: true,
            title: 'Case_dressings List'
        }
    },
    {
        path: '/case_dressings-list/:id',
        name: 'ViewCase_dressings',
        component: () => import('./../views/Case_dressings/Edit.vue'),
        meta: {
            auth: true,
            title: 'Case_dressings Edit'
        }
    },
    {
        path: '/cases-list',
        name: 'ListCases',
        component: () => import('./../views/Cases/List.vue'),
        meta: {
            auth: true,
            title: 'Cases List'
        }
    },
    {
        path: '/cases-list/:id',
        name: 'ViewCases',
        component: () => import('./../views/Cases/Edit.vue'),
        meta: {
            auth: true,
            title: 'Cases Edit'
        }
    },
    {
        path: '/patient_cases-list/:id',
        name: 'ViewPatientCases',
        component: () => import('./../views/Cases/PatientCases.vue'),
        meta: {
            auth: true,
            title: 'View Patient Cases'
        }
    },
    {
        path: '/new_patient_cases-list/:id',
        name: 'NewPatientCases',
        component: () => import('./../views/Cases/PatientNewCase.vue'),
        meta: {
            auth: true,
            title: 'New Patient Cases'
        }
    },
    {
        path: '/one_patient_cases/:id',
        name: 'ViewOnePatientCases',
        component: () => import('./../views/Cases/ViewOneCase.vue'),
        meta: {
            auth: true,
            title: 'One Patient Cases'
        }
    },
    {
        path: '/dressing_brands-list',
        name: 'ListDressing_brands',
        component: () => import('./../views/Dressing_brands/List.vue'),
        meta: {
            auth: true,
            title: 'Dressing_brands List'
        }
    },
    {
        path: '/dressing_brands-list/:id',
        name: 'ViewDressing_brands',
        component: () => import('./../views/Dressing_brands/Edit.vue'),
        meta: {
            auth: true,
            title: 'Dressing_brands Edit'
        }
    },
    {
        path: '/dressings-list',
        name: 'ListDressings',
        component: () => import('./../views/Dressings/List.vue'),
        meta: {
            auth: true,
            title: 'Dressings List'
        }
    },
    {
        path: '/dressings-list/:id',
        name: 'ViewDressings',
        component: () => import('./../views/Dressings/Edit.vue'),
        meta: {
            auth: true,
            title: 'Dressings Edit'
        }
    },
    {
        path: '/file_attachments-list',
        name: 'ListFile_attachments',
        component: () => import('./../views/File_attachments/List.vue'),
        meta: {
            auth: true,
            title: 'File_attachments List'
        }
    },
    {
        path: '/file_attachments-list/:id',
        name: 'ViewFile_attachments',
        component: () => import('./../views/File_attachments/Edit.vue'),
        meta: {
            auth: true,
            title: 'File_attachments Edit'
        }
    },
    {
        path: '/lab_test_cases-list',
        name: 'ListLab_test_cases',
        component: () => import('./../views/Lab_test_cases/List.vue'),
        meta: {
            auth: true,
            title: 'Lab_test_cases List'
        }
    },
    {
        path: '/lab_test_cases-list/:id',
        name: 'ViewLab_test_cases',
        component: () => import('./../views/Lab_test_cases/Edit.vue'),
        meta: {
            auth: true,
            title: 'Lab_test_cases Edit'
        }
    },
    {
        path: '/lab_tests-list',
        name: 'ListLab_tests',
        component: () => import('./../views/Lab_tests/List.vue'),
        meta: {
            auth: true,
            title: 'Lab_tests List'
        }
    },
    {
        path: '/lab_tests-list/:id',
        name: 'ViewLab_tests',
        component: () => import('./../views/Lab_tests/Edit.vue'),
        meta: {
            auth: true,
            title: 'Lab_tests Edit'
        }
    },
    {
        path: '/patient_surgeries-list',
        name: 'ListPatient_surgeries',
        component: () => import('./../views/Patient_surgeries/List.vue'),
        meta: {
            auth: true,
            title: 'Patient_surgeries List'
        }
    },
    {
        path: '/patient_surgeries-list/:id',
        name: 'ViewPatient_surgeries',
        component: () => import('./../views/Patient_surgeries/Edit.vue'),
        meta: {
            auth: true,
            title: 'Patient_surgeries Edit'
        }
    },
    {
        path: '/patients-list',
        name: 'ListPatients',
        component: () => import('./../views/Patients/List.vue'),
        meta: {
            auth: true,
            title: 'Patients List'
        }
    },

    {
        path: '/patients_view-list',
        name: 'Patients View',
        component: () => import('./../views/Patients/View.vue'),
        meta: {
            auth: false,
            title: 'Patients View'
        }
    },
    {
        path: '/appoint-list',
        name: 'Appoint View',
        component: () => import('./../views/Patients/Appoint.vue'),
        meta: {
            auth: false,
            title: 'Appoint View'
        }
    },
    {
        path: '/patients-list/:id',
        name: 'ViewPatients',
        component: () => import('./../views/Patients/Edit.vue'),
        meta: {
            auth: true,
            title: 'Patients Edit'
        }
    },
    {
        path: '/residencies-list',
        name: 'ListResidencies',
        component: () => import('./../views/Residencies/List.vue'),
        meta: {
            auth: true,
            title: 'Residencies List'
        }
    },
    {
        path: '/residencies-list/:id',
        name: 'ViewResidencies',
        component: () => import('./../views/Residencies/Edit.vue'),
        meta: {
            auth: true,
            title: 'Residencies Edit'
        }
    },
    {
        path: '/surgeries-list',
        name: 'ListSurgeries',
        component: () => import('./../views/Surgeries/List.vue'),
        meta: {
            auth: true,
            title: 'Surgeries List'
        }
    },
    {
        path: '/surgeries-list/:id',
        name: 'ViewSurgeries',
        component: () => import('./../views/Surgeries/Edit.vue'),
        meta: {
            auth: true,
            title: 'Surgeries Edit'
        }
    },
    {
        path: '/report',
        name: 'Reports',
        component: () => import('./../views/Reports/Report.vue'),
        meta: {
            auth: true,
            title: 'report'
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('./../views/About.vue'),
        meta: {
            auth: true,
            title: 'about'
        }
    },
    {
        path: '/users-list',
        name: 'ListUsers',
        component: () => import('./../views/Users/List.vue'),
        meta: {
            auth: true,
            title: 'Users List'
        }
    },
    {
        path: '/users-list/:id',
        name: 'ViewUsers',
        component: () => import('./../views/Users/Edit.vue'),
        meta: {
            auth: true,
            title: 'Users Edit'
        }
    },

    {
        path: '/wound_types-list',
        name: 'ListWound_types',
        component: () => import('./../views/Wound_types/List.vue'),
        meta: {
            auth: true,
            title: 'Wound_types List'
        }
    },
    {
        path: '/doctors-list',
        name: 'ListDoctors',
        component: () => import('./../views/Doctors/List.vue'),
        meta: {
            auth: true,
            title: 'Doctors List'
        }
    },
    {
        path: '/doctors-list/:id',
        name: 'ViewDoctors',
        component: () => import('./../views/Doctors/Edit.vue'),
        meta: {
            auth: true,
            title: 'Doctors Edit'
        }
    },
    {
        path: '/wound_types-list/:id',
        name: 'ViewWound_types',
        component: () => import('./../views/Wound_types/Edit.vue'),
        meta: {
            auth: true,
            title: 'Wound_types Edit'
        }
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes

})

// router.beforeEach((to, from, next) => {
//     const user = auth.currentUser;
//     if (to.matched.some(record => record.meta.auth) && !user) {
//         // Save the original requested route before redirecting to login
//         next({
//             path: '/login',
//             query: {
//                 redirect: to.fullPath
//             }
//         });
//     } else {
//         next();
//     }
// });

// // Add a global after hook to handle redirection after successful authentication
// router.beforeEach((to, from, next) => {
//     // const requiresAuth = to.matched.some(record => record.meta.auth)
//     const isAuthenticated = auth.currentUser

//     if (!isAuthenticated) {
//         next({
//             path: '/login',
//             query: {
//                 redirect: to.fullPath
//             }
//         })
//     } else {
//         next()
//     }
// })
router.afterEach((to, from) => {
    // Check if the route has a redirect query parameter
    if (to.query.redirect && to.matched.some(record => record.meta.auth)) {
        // If the user is authenticated, redirect to the original requested route
        if (auth.currentUser) {
            router.push(to.query.redirect);
        }
    }
});

export default router
