<template>
	<div class="text-center">
		<v-app-bar color="primary" class="thin-app-bar" style="justify-content: center;">
			<template v-slot:prepend>
				<v-menu >
					<template v-slot:activator="{ props }">
						<!-- <v-app-bar-nav-icon v-bind="props"></v-app-bar-nav-icon> -->
						<!-- change the icon into three dots -->
						<a href="/" style="text-decoration: none; color: white;">
							<v-icon>mdi-home</v-icon>
							<!-- {{ $store.getters.language.app_name }} -->
						</a>
						<v-btn icon v-bind="props">
							<v-icon>mdi-dots-vertical</v-icon>

						</v-btn>
					</template>
					<v-list>
						<v-list-item v-for="(item, index) in navigation_links" :key="index" :to="item.path">
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<v-spacer></v-spacer>

			<v-app-bar-title>
				<!-- <v-btn variant="text" to="/">
          <v-icon>mdi-home</v-icon>
          {{ $store.getters.language.app_name }}
        </v-btn> -->
			</v-app-bar-title>
			<v-spacer></v-spacer>
			<!-- change theme to dark btn -->
			<!-- <v-btn icon @click="toggleTheme">
            <v-icon>mdi-theme-light-dark</v-icon>
        </v-btn> -->

			<v-btn icon @click="logout">
				<v-icon>mdi-logout</v-icon>
			</v-btn>
		</v-app-bar>
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue';
	import { useStore } from 'vuex';
	const store = useStore();
	let drawer = ref(false);

	const navigation_links = computed(() => {
		const content = [

			// {
			//     path : '/antibiotic_and_medications-list',
			//     title : 'Antibiotic And Medications',
			// },

			// {
			//     path : '/case_doctor_plans-list',
			//     title : 'Case Doctor Plans',
			// },

			// {
			//     path : '/case_dressings-list',
			//     title : 'Case Dressings',
			// },

			// {
			//     path : '/cases-list',
			//     title : 'Cases',
			// },

			// {
			//     path : '/dressing_brands-list',
			//     title : 'Dressing Brands',
			// },

			// {
			//     path : '/dressings-list',
			//     title : 'Dressings',
			// },

			// {
			//     path : '/file_attachments-list',
			//     title : 'File Attachments',
			// },

			// {
			//     path : '/lab_test_cases-list',
			//     title : 'Lab Test Cases',
			// },

			// {
			//     path : '/lab_tests-list',
			//     title : 'Lab Tests',
			// },

			// {
			//     path : '/patient_surgeries-list',
			//     title : 'Patient Surgeries',
			// },

			{
				path: '/patients-list',
				title: 'New Patients',
			},
			{
				path: '/patients_view-list',
				title: 'Patients View',
			},

			// {
			//     path : '/residencies-list',
			//     title : 'Residencies',
			// },
			// {
			//     path : '/residencies-list',
			//     title : 'Residencies',
			// },

			// {
			//     path : '/surgeries-list',
			//     title : 'Surgeries',
			// },

			// {
			//     path : '/users-list',
			//     title : 'Users',
			// },

			// {
			//     path : '/wound_types-list',
			//     title : 'Wound Types',
			// },
			{
				path: '/options',
				title: 'Options',
			},
			{
				path: '/report',
				title: 'Reports',
			}
			,
			{
				path: '/about',
				title: 'About',
			}


		];
		return content;
	});
	const logout = () => {
		store.dispatch('logout');
	}
// const toggleTheme = () => {
//     store.dispatch('toggleTheme');
// }
</script>
        <style>
	.thin-app-bar {
		height: 50px;
	}
</style>